import { createRouter, createWebHistory } from 'vue-router'
//import { required } from 'vuelidate/lib/validators'
// import HomeView from '../views/HomeView.vue'

// import PcView from '../views/PcView.vue'

import { useAuthStore } from '../store/auth.store';

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView
    meta: { layout: 'main' , auth: true },
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/pclist',
    name: 'pclist',
    meta: { layout: 'main' , auth: true },
    // component: PcView
    component: () => import( '../views/PcListView.vue')
  },
  {
    path: '/origins',
    name: 'Origins',
    meta: { layout: 'main', auth: true  },
    component: () => import( '../views/OriginsView.vue')
  },
    {
    path: '/configs',
    name: 'Configs',
    meta: { layout: 'main', auth: true  },
    component: () => import( '../views/ConfigsView.vue')
  },
    {
    path: '/configs/:id',
    name: 'DetailConfig',
    meta: { layout: 'main', auth: true },
    component: () => import('../views/DetailConfig.vue'),

  },
  {
    path: '/login',
    name: 'Login',
    meta: {layout: 'empty', auth: false },
    component: () => import( '../views/LoginView.vue')
  },
  {
    path: '/detailpc/:uuid',
    name: 'DetailPc',
    meta: { layout: 'main', auth: true },
    component: () => import('../views/DetailPc.vue'),
    props: true
  },
  {
    path: '/origins/:id',
    name: 'DetailOrigin',
    meta: { layout: 'main', auth: true },
    component: () => import('../views/DetailOrigin.vue'),

  },
  {
    path: '/addorigin',
    name: 'AddOrigin',
    meta: { layout: 'main', auth: true },
    component: () => import('../views/OriginAddView.vue'),
  },

]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes  
})



router.beforeEach((to, from, next) => {
  
  const authStore = useAuthStore();
  //const requireAuth = to.matched.some(record => record.meta.auth)

  if (to.meta.auth && !authStore.isAuthenticated) {
    // next('/login?message=login')
    next('/login?message=login')
  } else {
    next()
  }
});


export default router
