import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'materialize-css/dist/js/materialize.min'

// import store from './store'
import { createPinia } from 'pinia' // Import

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import { formatDate } from './utils/utils';
const app = createApp(App);

app.config.globalProperties.$formatDate = formatDate;

app.use(createPinia()).use(router).component('EasyDataTable', Vue3EasyDataTable).mount('#app')
