<template>
  <ul class="sidenav app-sidenav" :class="{ open: isOpen }">
    <li v-for="(link,i) in links" :key="i">
      <!-- <a :href="link.url" class="waves-effect waves-orange pointer">{{link.title}}</a> -->
      <router-link :to=link.url  >
        {{ link.title }}
      </router-link>
    </li>



  </ul>
</template>



<script>
export default {
  props: ['isOpen'],
  data: () => ({

    links: [
      { title: 'Главная', url: '/', exact: true },
      { title: 'Регистрации ПК', url: '/pclist'},
      { title: 'Происхождения', url: '/origins'},
      { title: 'Конфигурации ПК', url: '/configs'},
      { title: 'TODO: Закупки', url: '/purchase'}
    ],

  })

}
</script>
