import { defineStore } from 'pinia';
import axios from 'axios';

import router from '../router/index'

const axiosInstance = axios.create({
  baseURL: 'https://go-pc-acc.itsrc.ru',
  //baseURL: 'http://localhost:8082',
  withCredentials: true,  // Включаем отправку cookie с каждым запросом
});



export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        isAuthenticated: false,
        cookie: null,
        returnUrl: null,
        user: null
    }),
    // Cookies: [],
    actions: {

        setAuth(cookie) {
          this.isAuthenticated = true;
          this.cookie = cookie;
        },
        clearAuth() {
          this.isAuthenticated = false;
          this.cookie = null;
        },
        
        async login(email, password) {
          const response = await axiosInstance.post('/dologin', {
              email: email,
              password: password,
          });
          
        //console.log(response.headers);
        this.setAuth(response.headers['set-cookie']);
        this.user = email,
        router.push('/pclist');
        },
        logout() {
          this.isAuthenticated = false;
          this.cookie = null;
            router.push('/login');
        }
    }
});


