<template>
  <div class="app-main-layout">
    <Navbar @click="isOpen = !isOpen" />
    <Sidebar :isOpen="isOpen" />
    <main class="app-content" :class="{ full: !isOpen }">
      <div class="app-page">
        <router-view />
      </div>
    </main>

  </div>
</template>


<script>
import Navbar from  '@/components/app/NavBar'
import Sidebar from '@/components/app/SideBar'

export default {
name: 'main-layout',
data: () => ({
    isOpen: true,
    loading: true
  }),
components: {
    Navbar, Sidebar
  },
}

</script>
