<template>
  <nav class="navbar orange lighten-1">
    <div class="nav-wrapper">
      <div class="navbar-left">
        <a href="#" @click="$emit('click', $event)">
          <i class="material-icons black-text">dehaze</i>
        </a>
        <span class="black-text">{{ dateNow }}</span>
      </div>

      <ul class="right hide-on-small-and-down">
        <li>
          <a class="dropdown-trigger black-text" href="#" data-target="dropdown">
            {{ user }}
            <i class="material-icons right">arrow_drop_down</i>
          </a>

          <ul id='dropdown' class='dropdown-content'>
            <li>
              <router-link to="/profile" class="black-text">
                <i class="material-icons">account_circle</i>Профиль
              </router-link>
            </li>
            <li class="divider" tabindex="-1"></li>
            <li>
              <a href="#" class="black-text" @click.prevent="logout">
                <i class="material-icons">assignment_return</i>Выйти
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script setup>
import { useAuthStore } from '@/store/auth.store';
import { storeToRefs } from 'pinia';

const { user } = storeToRefs(useAuthStore());
const dateNow = new Date().toLocaleDateString();


const { logout } = useAuthStore()


</script>
